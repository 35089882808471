import ALL_ACCOMMODATION_TYPES from '@/graphql/accommodation-types/query/getAllAccommodationTypes.gql'

const accommodationTypes = ref([])
const key = ref(0)
const firstTime = ref(true)

export function accommodationTypesAutocomplete(params: any) {
  const apolloQuery = ALL_ACCOMMODATION_TYPES
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: getAuthUser()?.value?.language?.toLowerCase() === 'en'
          ? 'TYPE_EN'
          : getAuthUser()?.value?.language?.toLowerCase() === 'es'
            ? 'TYPE_ES'
            : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
              ? 'TYPE_FR'
              : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                ? 'TYPE_DE'
                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                  ? 'TYPE_SV'
                  : 'TYPE_AR',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchAccommodationTypesCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await accommodationTypesAutocomplete({ search: queryString })
    cb(results?.getAllAccommodationTypes?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchAccommodationTypesCb')
  }
}

export function getSearchAccommodationTypes(entityAccommodation: any, entityAccommodations: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchAccommodationTypes('', entityAccommodation, entityAccommodations)
  }
  return accommodationTypes?.value ?? []
}

export async function searchAccommodationTypes(queryString: any, entityAccommodation: any, entityAccommodations: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await accommodationTypesAutocomplete({ search: queryString })
    const remoteAccommodationTypes = results?.getAllAccommodationTypes?.data?.map((i: any) => {
      return {
        label: getAuthUser()?.value?.language?.toLowerCase() === 'en'
          ? i.type_en
          : getAuthUser()?.value?.language?.toLowerCase() === 'es'
            ? i.type_es
            : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
              ? i.type_fr
              : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                ? i.type_de
                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                  ? i.type_sv
                  : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                    ? i.type_ar
                    : i.type_en,
        value: i.uuid
      }
    }) ?? []
    const accommodationsToAdd: { label: any; value: any }[] = []
    entityAccommodations?.forEach((j: any) => {
      if (!remoteAccommodationTypes.find((k: any) => k.value === j.uuid)) {
        accommodationsToAdd.push({
          label: getAuthUser()?.value?.language?.toLowerCase() === 'en'
            ? j.type_en
            : getAuthUser()?.value?.language?.toLowerCase() === 'es'
              ? j.type_es
              : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
                ? j.type_fr
                : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                  ? j.type_de
                  : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                    ? j.type_sv
                    : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                      ? j.type_ar
                      : j.type_en,
          value: j.uuid
        })
      }
    })
    if (entityAccommodation && !remoteAccommodationTypes.find((k: any) => k.value === entityAccommodation.uuid)) {
      accommodationsToAdd.push({
        label: getAuthUser()?.value?.language?.toLowerCase() === 'en'
          ? entityAccommodation.type_en
          : getAuthUser()?.value?.language?.toLowerCase() === 'es'
            ? entityAccommodation.type_es
            : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
              ? entityAccommodation.type_fr
              : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                ? entityAccommodation.type_de
                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                  ? entityAccommodation.type_sv
                  : getAuthUser()?.value?.language?.toLowerCase() === 'ar'
                    ? entityAccommodation.type_ar
                    : entityAccommodation.type_en,
        value: entityAccommodation.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    accommodationTypes.value = [...remoteAccommodationTypes, ...accommodationsToAdd]
    firstTime.value = false
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchAccommodationTypes')
  }
}

export function getAccommodationTypeKey () {
  return key.value
}

export function initialAccommodationTypes () {
  key.value = 0
  firstTime.value = true
  accommodationTypes.value = []
}